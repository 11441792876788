import React, { useState } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button
  } from '@mui/material';

import { ICardProduitPredef } from 'interfaces/common';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2';
const CardPanierPredef = ({produit, handleClick,trouve, handleDelete}:ICardProduitPredef) => {
  
  
  const [onHover, setOnHover] = useState(false)
  const style2 = {
    background: "#fff", borderRadius: "50%", padding:"0 5px", cursor:"pointer",
    display: onHover ? "block" : "none",
  };
  const clearProduct = () => {
    Swal.fire({
      title: 'Vous voulez supprimer le produit choisi?',
      showCancelButton: true,
      cancelButtonText: `Annuler`,
      confirmButtonText: 'Oui',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(produit.id)
      } 
    })
  }
  return (
    <Grid item xs={5.6} md={2.5} >
        <Box sx={{height:"100%", justifyContent:"space-between", background: "#fff", width:"fit-content", padding: "20px 5px", display: "flex", flexDirection:"column", gap:"5px", borderRadius: "10px", position: "relative"}} onMouseEnter={() => {
          setOnHover(true)
        }} onMouseLeave={() => {
          setOnHover(false)
        }}>
        <Box style={{ position: "absolute",top: "-8px",right: "-8px", zIndex: "100"}}>
          {
            trouve && <Box sx={style2}>
              <ClearIcon sx={{width: "16px"}} onClick={clearProduct} />
            </Box>
          }

        </Box>
        <Box style={{ position: "absolute",top: "20px",left: "3%", zIndex: "100"}}>
          {
            trouve && <Box sx={{background:"#92CE08", padding:"2px 5px 0",borderRadius:"5px"}}>
              <DoneIcon sx={{ color:"#fff", width: "16px"}} />
            </Box>
          }

        </Box>
        <Box sx={{flex:"auto"}}>
            <img src={`https://freshfood.kbdev.co/uploads/${produit.image}`} style={{width: "90%", margin: "0 auto", display:"block", height:"100%", objectFit:"contain"}}></img>
        </Box>
        <Box>
        <Typography>{produit.name}</Typography>
        <Typography>{produit.unit}</Typography>
        {
            produit.pricePromotion && <Box>
               <Typography sx={{ fontWeight: "bold", color:"#92CE08"}}>{`${produit.pricePromotion}.00 DA`}</Typography>
              <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                <Typography sx={{ fontWeight: "bold",color: "#C2C2C2", textDecoration:"line-through"}}>{`${produit.price}.00 DA`}</Typography>
                <Typography sx={{ fontSize:"0.8rem", color:"#92CE08"}}>{`- ${100 - Math.round(produit.pricePromotion*100/produit.price)}%`}</Typography>
              </Box>
          
          
            </Box>
          }
          {
            !produit.pricePromotion  && <Typography sx={{ fontWeight: "bold"}}>
          
            {`${produit.price}.00 DA`}</Typography>
          }
        </Box>
        <Button
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        sx={{
        height: 30,
        borderRadius: "3px",
        fontSize: "10px",
        fontWeight: "500"
        }}
        onClick={() => {
          handleClick(produit)
        }}
        >
            Ajouter au panier
        </Button>
        </Box>
        
    </Grid>
  )
}

export default CardPanierPredef