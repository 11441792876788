import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  LightTheme,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import { Title, Sider, Layout, Header } from "components/layout";
import { Login } from "pages/login";
import Produits from "pages/Produits";
import Favorites from "pages/Favorites";
import Panier from "pages/Panier";
import PanierPredef from "pages/PanierPredef";
import PanierPredefCreate from "pages/PanierPredefCreate";
import PaniersPredef from "pages/PaniersPredef";
import Commandes from "pages/Commandes";
import Commande from "pages/Commande";
import CommandeRedirect from "pages/CommandeRedirect";
import dataProvider from "@pankod/refine-simple-rest";
import { MuiInferencer } from "@pankod/refine-inferencer/mui";
import { authProvider } from "./authProvider";
import routerProvider from "@pankod/refine-react-router-v6";
import { AppProvider, useCtxt } from "contexts/app.context";
import axios, { AxiosRequestConfig } from "axios";
import Profil from "pages/Profil";
function App() {
  
  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = true
const overridedLightTheme = {
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: "#92CE08",
            contrastText: "#fff"
        },
        secondary: {
            main: "#fff",
            secondary: "#000",
            contrastText: "#000"
        },
        third: {
          main: "#F9A400"
        },
        error: {
          main: "#C80000",
          lighter: "#FFE8E8"
        },
        text: {
          disabled: "#c1c1c1",
          primary: "#000",
          secondary: "#373737",
        }
    },
};
  return (
    <ThemeProvider theme={overridedLightTheme}>
      <AppProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          /* @ts-ignore */
          dataProvider={dataProvider("https://freshclient.kbdev.co/api",axiosInstance)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: "products",
              list: Produits,
            },
            {
              name: "favorites",
              list: Favorites,
            },
            {
              name: "profil",
              list: Profil,
            },
            {
              name: "commandes",
              list: Commandes,
              show: Commande,
            },
            {
              name: "succes",
              list: CommandeRedirect,
            },
            {
              name: "panier",
              list: Panier,
            },
            {
              name: "panierPredefini",
              list: PaniersPredef,
              show: PanierPredef,
              create: PanierPredefCreate,
              canDelete: true,
            },
          ]}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          authProvider={authProvider}
          LoginPage={Login}
        />
      </RefineSnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
