
import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';

import CardFavorite from 'components/common/CardFavorite';
import { useNavigate } from "@pankod/refine-react-router-v6";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {  useCtxt } from "contexts/app.context";
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from "components/constante";
import axios from "axios"
import Swal from 'sweetalert2';

const Favoris = () => {
    const { ctxt } = useCtxt();    
    const navigate = useNavigate();
    const isMobile = useMediaQuery(mobileWidth)

    
    
    const [refresh,setRefresh]= useState(false)
    const [products,setProducts] = useState<any>([])
   
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    useEffect(() => {
        axios.get("/api/favorites").then((res) => {
            if (res.status === 200){
                let t =  res.data?.map((item : any) => {
             
                    return {...item, quantity : 1}
                   });
               setProducts(t)
            }
            setIsLoading(false)
        }).catch((e) => {
            console.log(e)
            setIsError(true)
            setIsLoading(false)
        })
        
    }, [refresh])
    const handleClick = () => {
        let total = 0
        const produits = products.map((pr: Produit) => {
            total += (pr.pricePromotion ? pr.pricePromotion : pr.price)*pr.quantity;
            return {
                productId: pr.id,
                name:pr.name,
                quantity:pr.quantity,
                collisage: pr.collisage,
                unit: pr.unit,
                price: pr.price,
                pricePromotion: pr.pricePromotion,
                image: pr.image
            }
        })
        axios.post("/api/orders",{
            totalPrice: total,
            products: produits,
        },{withCredentials: true})
        .then((res) => {
            if (res.status === 200){
                Swal.fire("Succes", "Ta commande est en cours de traitement");
                navigate("/commandes")
            }
            else {
                Swal.fire("Erreur", "Ta commande n'a pas été traité", "error");
            }
        })
        .catch((e) => {
            Swal.fire("Erreur", "Ta commande n'a pas été traité", "error");
        })
    }
    const removeFavorite = async (id: number) => {
        await axios.delete('/api/favorites/'+id, {
            withCredentials: true
        })
        .then((res) => {
          if (res.status === 200){
            Swal.fire("Succes", "Le produit a été supprimé des favoris correctement", "success");
            setRefresh(!refresh)
          }
        })
      }
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <>
    
    { !isMobile && <Box sx={{width: "100%", position: "relative", height: "100%", display: "flex", justifyContent: "center", margin: "10px 0"}}>
    {products.length > 0 && ( 
    <>
    
    <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent", padding:"0 10px", display: "flex", flexDirection: "column"}}>
        {products?.filter( (pr: any) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        }).map((produit : any, index : number) => {return (
            <CardFavorite key={produit.id} index={index} products={products} setProducts={setProducts} removeFavorite={removeFavorite} />
        ) })}
    </Grid>
    <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
        height: 40,
        borderRadius: "3px",
        fontSize: "10px",
        fontWeight: "500",
        margin:"0 auto",
        fontWweight: 600,
        padding: "30px 46px"
        }}
        onClick={() => {
          handleClick()
        }}
        >
            Commander Tout les articles
        </Button>
        </>
    )}
        {products.length===0 && (
          <Box  sx={{width: "100%",height:"100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} >
          <FavoriteBorderIcon sx={{color:"#CFCFCF",fontSize:180}} />
          <Typography color="#CFCFCF" variant='h4'>La liste des favoris est vide</Typography>
      </Box>
            )}
    </Box>}
    { isMobile && <Box sx={{width: "100%", position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center",padding:"20px 5px"}}>
    
    {products.length > 0 && ( 
    <>
    <Grid container rowSpacing={{xs: 1, sm: 1, md: 2 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", margin: "10px 0 10px", background:"transparent", padding: isMobile ? "0":"0 10px"}}>
        {products?.filter( (pr: any) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        }).map((produit : any, index : number) => {return (
            <CardFavorite key={produit.id} index={index} setProducts={setProducts} products={products} removeFavorite={removeFavorite} />
        ) })}
    </Grid>
    <Button
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        sx={{
        height: 40,
        borderRadius: "3px",
        fontSize: "10px",
        fontWeight: "500",
        margin:"0 auto"
        }}
        onClick={() => {
          handleClick()
        }}
        >
            Commander Tout les articles
        </Button>
        </>
    )}
        {products.length===0 && (
          <Box sx={{width: "100%",height:"100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <FavoriteBorderIcon sx={{color:"#CFCFCF",fontSize:80}} />
          <Typography color="#CFCFCF">La liste des favoris est vide</Typography>
      </Box>
            )}
    </Box>}
    </>
  )
}

export default Favoris