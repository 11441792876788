import {useState, useEffect} from 'react'
import {
    Grid,
    Box,
    Typography,
  } from '@mui/material';
  import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
  import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
  import AddIcon from '@mui/icons-material/Add';
  import RemoveIcon from '@mui/icons-material/Remove';
import { ICardFavorite,Produit } from 'interfaces/common';
import useMediaQuery from '@mui/material/useMediaQuery';
import {  useCtxt } from "contexts/app.context";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { mobileWidth } from 'components/constante';

const CardFavorite = ({products,index,removeFavorite, setProducts}:ICardFavorite) => {
  const [refresh ,setRefresh] = useState(false)
  const navigate = useNavigate();
  const { ctxt, dispatch } = useCtxt();
  const isMobile = useMediaQuery(mobileWidth)
  const ajoutPanier = () => {
    let trouve = false
      const t = ctxt.produitPanier;
      t.map((pr: Produit) => {
      if (pr.id === products[index]?.id) {
        trouve = true;
        pr.quantity += products[index].quantity
      }
    })
    if (trouve) {
      dispatch({ type: "setPanier",payload: t })
    }
    else {
      dispatch({ type: "setPanier",payload: [...t, products[index]] })
    }
  }
  useEffect(() => {

  },[refresh])
  
  return (
    <Grid item xs={12} sx={{height: "fit-content"}}>
         {!isMobile && <Box sx={{display: "flex", alignItems: "center", gap:"50px", background: "#fff", borderRadius: "13px", padding: "19px 16px"}}>
            <img src={`https://freshfood.kbdev.co/uploads/${products[index]?.image}`} style={{width: "100px", aspectRatio: "3/4", objectFit: "contain"}}></img>
            <Box sx={{flex: "auto", padding: "0 10px", display: "flex", gap: "10px", flexDirection: "column", fontWeight: "bold"}}>
                <Typography variant="h5">{products[index]?.name}</Typography>
                {
                products[index].pricePromotion && <Box>
                  <Typography sx={{ fontWeight: "bold", color:"#92CE08"}}>{`${products[index].pricePromotion}.00 DA`}</Typography>
                  <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <Typography sx={{ fontWeight: "bold",color: "#C2C2C2", textDecoration:"line-through"}}>{`${products[index].price}.00 DA`}</Typography>
                    <Typography sx={{ fontSize:"0.8rem", color:"#92CE08"}}>{`- ${100- Math.round(products[index].pricePromotion*100/products[index].price)}%`}</Typography>
                  </Box>
              
              
                </Box>
              }
              {
                !products[index].pricePromotion  && <Typography sx={{ fontWeight: "bold"}}>
              
                {`${products[index].price}.00 DA`}</Typography>
              }
                <Box sx={{ display: "flex", alignItems: "center", gap:"10px"}}>
                  <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff", padding: "6px 5px 0", cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                    let t = products
                    t[index].quantity += 1
                    setProducts(t)
                    setRefresh(!refresh)
                  }}>
                      <AddIcon sx={{ fontSize: 20}} />
                  </Box>
                  <Box sx={{width: "20px", textAlign:"center"}}>
                  <Typography>{`${products[index].quantity}`}</Typography>
                  </Box>
                  <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff",padding: "6px 5px 0",cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                    if (products[index].quantity > 0){
                      let t = products
                    t[index].quantity -= 1
                    setProducts(t)
                    setRefresh(!refresh)
                    }
                  }}>
                      <RemoveIcon sx={{fontSize: 20}} />
                  </Box>
              </Box>
            </Box>
            
            <Box sx={{ display: "flex", gap: "10px"}}>
                <DeleteOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  removeFavorite(products[index].id)
                }} />
                <LocalGroceryStoreOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  ajoutPanier()
                  navigate("/panier")
                }} />
            </Box>
        </Box>}
        {isMobile && <Box sx={{display: "flex", alignItems: "center", gap:"10px", background: "#fff", borderRadius: "13px", padding: "10px 10px"}}>
            <img src={`https://freshfood.kbdev.co/uploads/${products[index]?.image}`} style={{width: "60px", objectFit: "contain"}}></img>
            <Box sx={{flex: "auto", padding: "0 10px", display: "flex", gap: "5px", flexDirection: "column", fontWeight: "bold"}}>
                <Typography >{products[index]?.name}</Typography>
                <Box>
            {
            products[index].pricePromotion && <Box>
               <Typography sx={{ fontWeight: "bold", color:"#92CE08",fontSize:"0.8rem"}}>{`${products[index].pricePromotion}.00 DA`}</Typography>
              <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                <Typography sx={{ fontWeight: "bold",color: "#C2C2C2", textDecoration:"line-through",fontSize:"0.8rem"}}>{`${products[index].price}.00 DA`}</Typography>
                <Typography sx={{  color:"#92CE08",fontSize:"0.6rem"}}>{`- ${100- Math.round(products[index].pricePromotion*100/products[index].price)}%`}</Typography>
              </Box>
          
          
            </Box>
          }
          {
            !products[index].pricePromotion  && <Typography sx={{ fontWeight: "bold",fontSize:"0.8rem"}}>
          
            {`${products[index].price}.00 DA`}</Typography>
          }
            </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap:"5px"}}>
                  <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff", padding: "1px 4px 0", cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                    let t = products
                    t[index].quantity += 1
                    setProducts(t)
                    setRefresh(!refresh)
                  }}>
                      <AddIcon sx={{ fontSize: 15}} />
                  </Box>
                  <Box sx={{width: "20px", textAlign:"center"}}>
                  <Typography>{`${products[index].quantity}`}</Typography>
                  </Box>
                  <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff",padding: "1px 4px 0",cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                    if (products[index].quantity > 0){
                      let t = products
                    t[index].quantity -= 1
                    setProducts(t)
                    setRefresh(!refresh)
                    }
                  }}>
                      <RemoveIcon sx={{fontSize: 15}} />
                  </Box>
              </Box>
              
            </Box>
            
            <Box sx={{ display: "flex", gap: "5px"}}>
                <DeleteOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  removeFavorite(products[index].id)
                }} />
                <LocalGroceryStoreOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                  ajoutPanier()
                  navigate("/panier")
                }} />
            </Box>
        </Box>}
        
    </Grid>
  )
}

export default CardFavorite