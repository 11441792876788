import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    Button,
    Stack, Select,MenuItem, TextField, InputAdornment
  } from '@mui/material';
  import SearchIcon from '@mui/icons-material/Search';
import CardProduit from 'components/common/CardProduit';
import ModalProduct from 'components/common/ModalProduct';
import { useNavigate } from "@pankod/refine-react-router-v6";
import { useTable, useList } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import {  useCtxt } from "contexts/app.context";
import { useMediaQuery } from '@pankod/refine-mui';
import { mobileWidth } from 'components/constante';
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import axios from 'axios';
const Produits = () => {
    const { ctxt, dispatch } = useCtxt();
    const isMobile = useMediaQuery(mobileWidth)
    const navigate = useNavigate();

    const {
        tableQueryResult: { data, isLoading, isError },
        sorter,
        setSorter,
    } = useTable();
    const currentPrice = sorter.find((item) => item.field === "price")?.order;

    const toggleSort = (field: string) => {
        setSorter([{ field, order: currentPrice === "asc" ? "desc" : "asc" }]);
    };
    
   
    /* @ts-ignore */
    const products = data?.data ?? [];
    const [value, setValue] = React.useState('');
  const handleChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
    setValue( event.target.value );
  };
  const handleRecherche = () => {
    dispatch({ type: "setRecherche",payload: value })
  }
  useEffect(() => {
    dispatch({ type: "setRecherche",payload: value })
  }, [value])
    const [selectedItem, setSelectedItem] = useState<Produit>()
    
    const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const clickProduit = (item : Produit) => {
    setSelectedItem(item)
    setOpenModal(true)
  }
  const [categories, setCategories] = useState<any[]>([])
  const [category, setCategory]  = useState("")
    useEffect(() => {
      axios.get(`/api/settings/category`).then((res) => {
        if (res.status === 200){
            setCategories(res.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    
      
    }, [])
    
    
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{postion:"relative", background: "transparent"}}>
    
    <ModalProduct item={selectedItem} open={openModal} handleClose={handleClose} type="normal" />
     <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                <Stack direction="column" width="100%">
                    {isMobile && <TextField
                        id="input-with-icon-textfield"
                        label="Rechercher"
                        name="recherche"
                        placeholder="Rechercher un produit"
                        style={{flex: "auto", background: "#ffffff", margin:"0 20px"}}
                        onChange={handleChange}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                            <SearchIcon sx={{ cursor: "pointer"}} onClick={() => {
                            handleRecherche()
                            }} />
                            </InputAdornment>
                        ),
                        }}
                        variant="outlined"
                    />}
                    <Box
                        mb={2}
                        mt={3}
                        display="flex"
                        width="84%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Box
                            display="flex"
                            gap={2}
                            flexWrap="wrap"
                            mb={{ xs: "20px", sm: 0 }}
                        >
                            {/* <Button
                                onClick={() => toggleSort("price")}
                            >
                                {`Sort price ${
                                    currentPrice === "asc" ? "↑" : "↓"
                                }`}
                            </Button> */}
                            <Select
                                variant="outlined"
                                color="info"
                                displayEmpty
                                required
                                inputProps={{ "aria-label": "Without label" }}
                                value={category}
                                onChange={(e) => {
                                    setCategory(e.target.value)
                                }}
                            >
                                <MenuItem value="">Toute les catégories</MenuItem>
                                {categories.map((categorie) => (
                                    <MenuItem
                                        key={categorie.id}
                                        value={categorie.name.toLowerCase()}
                                    >
                                        {categorie.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </Stack>
            </Box>
            {products.length > 0 && (
    <Grid container rowSpacing={{xs: 1, sm: 1, md: 2 }} columnSpacing={2} sx={{position: "relative", background:"transparent", padding:"0 10px", marginBottom: "60px"}}>
        {products?.filter( (pr) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        })?.filter( (pr) => {
            if (pr.category.name.includes(category)){
                return pr;
            }
            return false;
        }).length === 0 && <Box sx={{width:"100%",display:"flex",flexDirection:"column", alignItems:"center"}}>
          <SentimentVeryDissatisfied sx={{color:"#CFCFCF",fontSize:180}} />
          <Typography color="#CFCFCF" variant='h4'>Aucun article trouvé</Typography>
        </Box>}
        {products?.filter( (pr) => {
            if (pr.name.includes(ctxt.recherche)){
                return pr;
            }
            return false;
        })?.filter( (pr) => {
            if (pr.category.name.includes(category)){
                return pr;
            }
            return false;
        }).map((produit : any) => {return (
            <CardProduit key={produit.id} produit={produit} handleClick={() => {
                clickProduit(produit)
            }} />
            
        ) })}
    </Grid>
            )}
            {products.length===0 && (
                <Typography>Aucun produit trouvé</Typography>
            )}
    </Box>
  )
}

export default Produits