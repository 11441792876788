import React, { useState,useContext, useEffect } from "react";
import {  useLogout,useGetIdentity } from "@pankod/refine-core";
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  TextField,InputAdornment,Box,Link
} from "@pankod/refine-mui";
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {  useCtxt } from "contexts/app.context";
import { IUser} from "interfaces/common"
import { useNavigate } from "@pankod/refine-react-router-v6";
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { mobileWidth } from "components/constante";
import logo from "../../../assets/logo.svg"
export const Header: React.FC = () => {
  const { mutate: logout } = useLogout();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(mobileWidth)
  const { data: userInfo } = useGetIdentity<IUser>();
  
  useEffect(() => {
    dispatch({ type: "setUser",payload: userInfo })
  }, [userInfo])
  
  const { ctxt: {user, produitPanier}, dispatch } = useCtxt();
  const [show,setShow] = useState(false)
  const [value, setValue] = React.useState('');
  const handleChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
    setValue( event.target.value );
  };
  const handleRecherche = () => {
    dispatch({ type: "setRecherche",payload: value })
  }
  useEffect(() => {
    dispatch({ type: "setRecherche",payload: value })
  }, [value])
  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.
  const pagesMobile : any = {
    "/products":"Liste des Articles",
    "/favorites":"liste Favoris",
    "/recherche":"Recherche",
    "/panier":"Panier",
    "/succes":"Panier"
  }
  const subPagesMobile : any = {
    "/commandes/show":"Details de la commande",
    "/panierPredefini":"panier prédéfini",
    "/panierPredefini/create":"nouveau panier",
    "/panierPredefini/show":" modifier le panier",
    "/profil":"VOTRE PROFILE",
    "/commandes":"Commandes"

  }
  return shouldRenderHeader ? (
    <AppBar color="default" position="sticky" elevation={1} sx={{background: "transparent",}}>
      <Toolbar sx={{ padding:"0"}}>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          padding="10px 20px"
          bgcolor="#F6F6F6"
          gap={isMobile ? "10px":"15px"}
        >
          {(!isMobile || pagesMobile[window.location.pathname]) &&<Box sx={{width: isMobile ? "40px":"95px"}}>
            <img src={logo} alt="logo img" />
          </Box>}
          {!isMobile && <TextField
            id="input-with-icon-textfield"
            label="Rechercher"
            name="recherche"
            placeholder="Rechercher un produit"
            style={{flex: "auto", background: "#ffffff"}}
            onChange={handleChange}
            InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                <SearchIcon sx={{ cursor: "pointer"}} onClick={() => {
                  handleRecherche()
                }} />
                </InputAdornment>
            ),
            }}
            variant="outlined"
        />}
        {
              isMobile && subPagesMobile[window.location.pathname.split("/", 3).join("/")] && <KeyboardArrowLeftOutlined sx={{ cursor:"pointer", fontSize:40}} onClick={() => {
                navigate("/products")
              }} />
            }
        {
          isMobile && <Typography sx={{ textTransform:"capitalize", fontWeight:"bold"}}>{ pagesMobile[window.location.pathname] ||  subPagesMobile[window.location.pathname.split("/", 3).join("/")]}</Typography>
        }
        {!isMobile && <>
          <Box sx={{cursor:"pointer"}} onClick={() => {
          navigate("/favorites")
        }}>
            <FavoriteBorderIcon style={{ color: '#acacac', fontSize: 30 }} />
        </Box>
        <Box style={{position: "relative", cursor:"pointer"}} onClick={() => {
          navigate("/panier")
        }}>
            <ShoppingCartOutlinedIcon style={{ color: '#acacac', fontSize: 30 }} /> {produitPanier.length > 0 && <span style={{ backgroundColor: "#92CE08", padding: "3px 7px", borderRadius:"40px", color: "white", position: "absolute", fontSize: "0.5rem", bottom: "0%", left:"15px"}}>{produitPanier.length}</span>}
        </Box>
        </>}
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            {(!isMobile || pagesMobile[window.location.pathname]) && user? (
              <Box sx={{ backgroundColor: "#fff", padding: "12px", borderRadius: "10px", cursor: "pointer"}} color="#92CE08" onClick={() => {
                if (!isMobile) {
                  setShow(!show)
                }
                else {
                  navigate("/profil")
                }
              }}>
                <Typography style={{ textTransform: "uppercase"}} >{user?.Client?.gerant?.substring(0,2)}</Typography>
            </Box>
            ) : null}
            
          </Stack>
        </Stack>
        {show && !isMobile && <Box sx={{ position:"absolute", top:"90%", right:"20px", display:"flex", flexDirection:"column", background:"#fff",minWidth:"350px", borderRadius:"16px", padding:"18px 14px", gap:"15px"}}>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Nom</Typography>
                <Typography sx={{color:"#6D6D6D", textTransform:"uppercase"}}>{user?.Client?.gerant} </Typography>
              </Box>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Email</Typography>
                <Typography sx={{color:"#6D6D6D"}}>{user?.email}</Typography>
              </Box>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Numéro de téléphone</Typography>
                <Typography sx={{color:"#6D6D6D"}}>{user?.phone_number}</Typography>
              </Box>
              <Box sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between",background:"#FFE8E8",color:"#C80000",borderRadius:"9px", padding:"13px 17px",cursor:"pointer"}} onClick={() => {logout()}}>
                <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
                <LogoutOutlinedIcon sx={{color:"#C80000"}} />
                <Typography sx={{fontSize: "12px", width:"max-content", textTransform: "uppercase"}}>Déconnexion</Typography>
                </Box>
                <KeyboardArrowRightOutlined />
            </Box>
          </Box>}
      </Toolbar>
    </AppBar>
  ) : null;
};
