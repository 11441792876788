import {useState, useEffect} from 'react'
import { IModal, Produit } from 'interfaces/common';
import {
    Box,
    Typography,
    Modal,Button
  } from '@mui/material';
  import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RemoveIcon from '@mui/icons-material/Remove';
import useMediaQuery from '@mui/material/useMediaQuery';
import {  useCtxt } from "contexts/app.context";
import { mobileWidth } from "components/constante";
const ModalProduct = ({item,open, handleClose, type,items, setItems}:IModal) => {
  const { ctxt, dispatch } = useCtxt();
  const isMobile = useMediaQuery(mobileWidth)
  const [nbr, setNbr] = useState(1)
  useEffect(() => {
    if(type ==="predef"){
      if (item && item.quantity){
        setNbr(item.quantity)
      }
      
    }
  }, [item])
  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    minWidth: 400,
    bgcolor: '#F9F9F9',
    border: "14px",
    boxShadow: 24,
    borderRadius: "14px",
    p: 4,
  };
  const style2 = {
    position: 'absolute' as 'absolute',
    bottom: '0%',
    left: '0%',
    width: '100%',
    bgcolor: '#F9F9F9',
    border: "14px",
    boxShadow: 24,
    borderRadius: "14px 14px 0 0",
    padding: "25px 10px",
  };
  const ajoutPanier = () => {
    if (type === "normal"){
      let trouve = false
      const t = ctxt.produitPanier;
      t.map((pr: Produit) => {
        if (pr.id === item?.id) {
          trouve = true;
          pr.quantity += nbr
        }
      })
      if (trouve) {
        dispatch({ type: "setPanier",payload: t })
      }
      else {
        dispatch({ type: "setPanier",payload: [...t, {...item, quantity: nbr}] })
      }
    }
    if (type==="predef"){
      const t = items;
      let trouve = false
      t?.map((el) => {
        if (el.id === item?.id){
          trouve = true;
          el.quantity = nbr
        }
      })
      if (trouve === false){
        t?.push({...item, quantity: nbr})
      }
      setItems(t)
      console.log(items)
    }
   
    
    handleClose()
    setNbr(1)
  }
    return (
       <>
       {!isMobile &&  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx ={style}>
        <Box sx={{ display: "flex", alignItems:"center", justifyContent: "space-between", background: "#ffffff", padding:"10px 15px",borderRadius:"10px"}}>
            <Typography sx={{textTransform: "uppercase", fontWeight: "bold", display:"flex", alignItems: "center"}}><AttachMoneyIcon /> SOUS-Total</Typography>
            <Typography>{`${item?.price ? item?.price*nbr: 0} DA`}</Typography>
            
        </Box>
         <Box sx={{display: "flex", alignItems: "center", gap:"20px"}}>
            <img src={`https://freshfood.kbdev.co/uploads/${item?.image}`} style={{width: "80px",flex: "1", aspectRatio: "3/4", objectFit: "contain"}}></img>
            <Box sx={{flex: "1"}}>
                <Typography>{item?.name}</Typography>
                <Typography>{item?.unit}</Typography>
                <Typography sx={{ fontWeight: "bold"}}>{`${item?.price}DA`}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", flex:"auto", justifyContent: "flex-end", gap:"10px"}}>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff", padding: "6px 5px 0", cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                  setNbr(nbr+1)
                }}>
                    <AddIcon sx={{fontSize: 30}} />
                </Box>
                <Box sx={{width: "20px", textAlign:"center"}}>
                <Typography>{`${nbr}`}</Typography>
                </Box>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff",padding: "6px 5px 0",cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                  if (nbr > 0){
                    setNbr(nbr-1)
                  }
                }}>
                    <RemoveIcon sx={{fontSize: 30}} />
                </Box>
            </Box>
        </Box>
        <Box sx={{ display: "flex",gap: "10px" }}>
            <Button
                variant="contained"
                size="small"
                color="secondary"
                sx={{
                height: 30,
                borderRadius: "3px",
                fontSize: "10px",
                fontWeight: "500",
                flex:"1",
                background: "#fff",
                color: "#000"
                }}
                
                onClick={(e) => {handleClose()}}
            >
                Annuler
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                height: 30,
                borderRadius: "3px",
                fontSize: "10px",
                fontWeight: "500",
                flex:"1"
                }}
                onClick={ajoutPanier}
            >
                Ajouter au panier
            </Button>
        </Box>
        </Box>
        
      </Modal>}
      {isMobile &&  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx ={style2}>
        <Box sx={{ display: "flex", alignItems:"center", justifyContent: "space-between", background: "#ffffff", padding:"10px 15px",borderRadius:"10px"}}>
            <Typography sx={{textTransform: "uppercase", fontWeight: "bold", display:"flex", alignItems: "center"}}><AttachMoneyIcon /> SOUS-Total</Typography>
            <Typography> {`${item?.price ? item?.price*nbr: 0} DA`}</Typography>
            
        </Box>
         <Box sx={{display: "flex", alignItems: "center", gap:"20px"}}>
            <img src={`https://freshfood.kbdev.co/uploads/${item?.image}`} style={{width: "80px",flex: "1", aspectRatio: "3/4", objectFit: "contain"}}></img>
            <Box sx={{flex: "1"}}>
                <Typography>{item?.name}</Typography>
                <Typography>{item?.unit}</Typography>
                <Typography sx={{ fontWeight: "bold"}}>{`${item?.price}DA`}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", flex:"auto", justifyContent: "flex-end", gap:"10px"}}>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff", padding: "6px 5px 0", cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                  setNbr(nbr+1)
                }}>
                    <AddIcon sx={{fontSize: 20}} />
                </Box>
                <Box sx={{width: "20px", textAlign:"center"}}>
                <Typography>{`${nbr}`}</Typography>
                </Box>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff",padding: "6px 5px 0",cursor:"pointer", borderRadius: "4px"}} onClick={() => {
                  setNbr(nbr-1)
                }}>
                    <RemoveIcon sx={{fontSize: 20}} />
                </Box>
            </Box>
        </Box>
        <Box sx={{ display: "flex",gap: "10px" }}>
            <Button
                variant="contained"
                size="small"
                sx={{
                height: 30,
                borderRadius: "3px",
                fontSize: "10px",
                fontWeight: "500",
                flex:"1",
                background: "#fff",
                color: "#000"
                }}
                onClick={(e) => {handleClose()}}
            >
                Annuler
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                height: 30,
                borderRadius: "3px",
                fontSize: "10px",
                fontWeight: "500",
                flex:"1"
                }}
                onClick={ajoutPanier}
            >
                Ajouter au panier
            </Button>
        </Box>
        </Box>
        
      </Modal>}

       </>
    )
}
export default ModalProduct